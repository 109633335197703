import {
	GET_ERROR,
	GET_PRODUCT,
	CHANGE_PRICE,
	CLEAR_PRODUCT,
} from "../actions/types";

const initialState = {
	product: null,
	loading: true,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_PRODUCT:
			return {
				...state,
				loading: false,
				product: payload,
			};
		case CHANGE_PRICE:
			return {
				...state,
				product: state.product.map((item) =>
					item._id === payload._id ? { ...item, price: payload.price } : item
				),
			};
		case GET_ERROR:
			return {
				...state,
				loading: false,
			};
		case CLEAR_PRODUCT:
			return {
				...state,
				product: null,
				loading: true,
			};
		default:
			return state;
	}
}
