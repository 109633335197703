import React, { useState } from "react";

function ChangePrices({ changePrice, product }) {
	const [formData, setFormData] = useState({
		productId: "",
		price: "",
	});
	const { productId, price } = formData;
	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });
	return (
		<>
			<div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
				<div className="mt-4 text-sm">
					<span className="text-gray-700 dark:text-gray-400">Print Type</span>
					<div className="mt-2">
						<label className="inline-flex items-center text-gray-600 dark:text-gray-400">
							<input
								onChange={(e) => onChange(e)}
								type="radio"
								className="text-purple-600 focus:ring-purple-200 focus:ring-opacity-50 focus:ring"
								value={product[0]._id}
								name="productId"
							></input>
							<span className="ml-2">Mono</span>
						</label>
						<label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400 sm:pt-10">
							<input
								type="radio"
								onChange={(e) => onChange(e)}
								className="text-purple-600 focus:ring-purple-200 focus:ring-opacity-50 focus:ring"
								value={product[1]._id}
								name="productId"
							></input>
							<span className="ml-2">Colored</span>
						</label>
					</div>
				</div>
				<label className="block mt-4 text-sm">
					<span className="text-gray-700 dark:text-gray-400">Price</span>
					<input
						onChange={(e) => onChange(e)}
						type="text"
						name="price"
						className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded-lg dark:bg-gray-700 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
					/>
				</label>
				<div className="flex items-center w-full">
					<button
						onClick={() => {
							changePrice(productId, price);
						}}
						className="px-4 py-2 block mt-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-200"
					>
						Change
					</button>
				</div>
			</div>
		</>
	);
}

export default ChangePrices;
