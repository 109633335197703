import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { login } from "../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Login = ({ login, isAuthenticated, user }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated && user) {
    if (user.role === "full") {
      return <Redirect to="/dashboard" />;
    } else if (user.role === "tenant") {
      return <Redirect to="/tenant" />;
    }
    return <Redirect to="/printer" />;
  }

  return (
    <>
      <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
        <div className="xl:w-1/2 lg:w-3/4 w-full my-10 mx-5 md:mx-auto text-center md:mt-40">
          <div className="bg-white dark:bg-gray-800 w-full max-w-sm rounded-lg shadow-md overflow-hidden mx-auto">
            <div className="flex items-center justify-center py-4 bg-purple-600 dark:bg-purple-600 text-center">
              <span className="text-gray-600 dark:text-gray-200 text-sm"></span>
            </div>
            <div className="py-4 px-6">
              <h2 className="text-center font-bold text-gray-700 dark:text-white text-3xl">
                CloudPrint
              </h2>

              <form onSubmit={onSubmit}>
                <div className="mt-4 w-full">
                  <input
                    className="w-full mt-2 py-2 px-4 bg-white dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded block placeholder-gray-500 dark:text-gray-200 dark:placeholder-gray-400 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
                    type="email"
                    name="email"
                    onChange={(e) => onChange(e)}
                    placeholder="Username"
                    aria-label="Username"
                  />
                </div>

                <div className="mt-4 w-full">
                  <input
                    className="w-full mt-2 py-2 px-4 bg-white text-gray-700 dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded block placeholder-gray-500 dark:text-gray-200 dark:placeholder-gray-400 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
                    type="password"
                    name="password"
                    placeholder="Password"
                    aria-label="Password"
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <div className="flex justify-center items-center mt-4">
                  <button
                    className="py-2 px-4 bg-purple-600 w-3/5 text-white rounded transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>

            <div className="flex items-center justify-center py-4 bg-purple-600 dark:bg-purple-600 text-center">
              <span className="text-gray-600 dark:text-gray-200 text-sm"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
