import React, { useRef, useEffect, useState } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import Toggle from "./toggle";
import { logout } from "../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Navbar = ({
	auth: { isAuthenticated, loading, user },
	changeTheme,

	darkMode,
	logout,
	collapseShow,
	setCollapseShow,
	profileShow,
	setProfileShow,
	notificationShow,
	setNotificationShow,
}) => {
	const ref = useRef();
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			setProfileShow(!profileShow);
			setNotificationShow(true);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});

	const refNotification = useRef();
	const handleClickNotification = (e) => {
		if (
			refNotification.current &&
			!refNotification.current.contains(e.target)
		) {
			setNotificationShow(!notificationShow);
			setProfileShow(true);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickNotification);

		return () => {
			document.removeEventListener("click", handleClickNotification);
		};
	});

	return (
		<header className="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
			<div className="container flex items-center md:justify-end justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
				<button
					className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:ring focus:ring-purple-200"
					aria-label="Menu"
					onClick={() => setCollapseShow(!collapseShow)}
				>
					<svg
						className="w-6 h-6"
						aria-hidden="true"
						fill="currentColor"
						viewBox="0 0 20 20"
					>
						<path
							fillRule="evenodd"
							d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
							clipRule="evenodd"
						></path>
					</svg>
				</button>

				<ul className="flex items-center flex-shrink-0 space-x-6">
					<li className="flex">
						<Toggle />
					</li>

					<li className="relative">
						<button
							className="relative align-middle rounded-md focus:outline-none focus:ring focus:ring-purple-200"
							aria-label="Notifications"
							aria-haspopup="true"
							onClick={() => {
								setNotificationShow(!notificationShow);
							}}
						>
							<svg
								className="w-5 h-5"
								aria-hidden="true"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
							</svg>

							<span
								aria-hidden="true"
								className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
							></span>
						</button>
						{!notificationShow ? (
							<div ref={refNotification}>
								<ul className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700">
									<li className="flex">
										<a
											className="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
											href="#"
										>
											<span>Printer Errors</span>
											<span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-600">
												13
											</span>
										</a>
									</li>
									<li className="flex">
										<a
											className="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
											href="#"
										>
											<span>Sales</span>
											<span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-600">
												2
											</span>
										</a>
									</li>
									<li className="flex">
										<a
											className="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
											href="#"
										>
											<span>Alerts</span>
										</a>
									</li>
								</ul>
							</div>
						) : (
							""
						)}
					</li>

					<li className="relative">
						<button
							className="align-middle rounded-full focus:outline-none focus:ring focus:ring-purple-200"
							aria-label="Account"
							aria-haspopup="true"
							onClick={() => {
								setProfileShow(!profileShow);
							}}
						>
							<img
								className="object-cover w-8 h-8 rounded-full"
								src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScuQGyYbgV9HFyiunO9mF6_lnB6MYwcx6t3w&usqp=CAU"
								alt=""
								aria-hidden="true"
							/>
						</button>
						{!profileShow ? (
							<div ref={ref}>
								<ul
									className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
									aria-label="submenu"
								>
									<li className="flex">
										<a
											className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
											href="#"
										>
											<svg
												className="w-4 h-4 mr-3"
												aria-hidden="true"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
											</svg>
											<span>Profile</span>
										</a>
									</li>
									<li className="flex">
										<a
											className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
											href="#"
										>
											<svg
												className="w-4 h-4 mr-3"
												aria-hidden="true"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
												<path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
											</svg>
											<span>Settings</span>
										</a>
									</li>
									<li className="flex">
										<a
											className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
											onClick={logout}
										>
											<svg
												className="w-4 h-4 mr-3"
												aria-hidden="true"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
											</svg>
											<span>Log out</span>
										</a>
									</li>
								</ul>
							</div>
						) : (
							""
						)}
					</li>
				</ul>
			</div>
		</header>
	);
};

Navbar.propTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	darkMode: state.design.darkMode,
});

export default connect(mapStateToProps, {
	logout,
})(Navbar);
