import React, { useEffect, Fragment, useState } from "react";
import Admin from "../layout/Admin";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProduct, changePrice } from "../../actions/settings";
import PrintingPrices from "./PrintingPrices";
import ChangePrices from "./ChangePrices";
import Spinner from "../layout/Spinner";

const Settings = ({ auth, getProduct, changePrice, product, loading }) => {
	useEffect(() => {
		getProduct();
	}, [getProduct]);

	return (
		<>
			{!loading ? (
				<Admin>
					<h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
						Settings
					</h2>
					<h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
						Current prices
					</h4>
					<PrintingPrices product={product} />
					<h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
						Change prices
					</h4>
					<ChangePrices changePrice={changePrice} product={product} />
				</Admin>
			) : (
				<Spinner />
			)}
		</>
	);
};

Settings.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	product: state.settings.product,
	loading: state.settings.loading,
});

export default connect(mapStateToProps, { getProduct, changePrice })(Settings);
