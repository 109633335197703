import React, { useEffect, Fragment, useState } from "react";
import Admin from "../layout/Admin";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCounts, getIncome } from "../../actions/dashboard";
import Spinner from "../layout/Spinner";
import PrinterIncome from "./PrinterIncome";
import DatePicker from "react-datepicker";
import { subDays, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import PrinterIncomeTable from "./PrinterIncomeTable";

const Dashboard = ({ getCounts, getIncome, dashboard, loading }) => {
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()
  );
  const [formData, setFormData] = useState({
    startDate,
    endDate,
  });
  useEffect(() => {
    getCounts();
    getIncome({ startDate, endDate });
  }, [getCounts, getIncome]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const startDateChanger = (date) => {
    setStartDate(date);
    setFormData({ ...formData, startDate: date.toISOString() });
  };
  const endDateChanger = (date) => {
    setEndDate(date);
    setFormData({ ...formData, endDate: date.toISOString() });
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {!loading ? (
        <Admin>
          <>
            <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Dashboard
            </h2>
            <div className="flex items-center justify-between p-4 mb-8 text-sm font-semibold text-purple-100 bg-purple-600 rounded-lg shadow-md focus:outline-none focus:shadow-outline-purple">
              <div className="flex items-center">
                <img src="/img/wise.png" className="w-9 h-9 mr-2" />
                <span>Technology has to be invented or adopted.</span>
              </div>
            </div>
            <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Total clients
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {dashboard.userCount}
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Total printers
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {dashboard.printerCount}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 mb-6">
                <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
                  <span className="mr-2 text-gray-600 dark:text-gray-400">
                    From:
                  </span>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(e) => onChange(e)}
                    className="text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md focus:border-purple-500 focus:outline-none"
                  ></input>
                </label>
                <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
                  <span className="mr-2 text-gray-600 dark:text-gray-400">
                    To:
                  </span>
                  <input
                    type="date"
                    name="endDate"
                    onChange={(e) => onChange(e)}
                    className="text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md focus:border-purple-500 focus:outline-none"
                  ></input>
                </label>
                <div className="ml-6">
                  <button
                    className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-200"
                    onClick={() => {
                      getIncome(formData);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <PrinterIncome printerData={dashboard.printerIncome} />
            </div>
            <div className="mb-8">
              <PrinterIncomeTable printerData={dashboard.printerIncome} />
            </div>
          </>
        </Admin>
      ) : (
        <Spinner />
      )}
    </>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  loading: state.dashboard.loading,
});

export default connect(mapStateToProps, { getCounts, getIncome })(Dashboard);
