import React, { useRef, useEffect, useState } from "react";
import Admin from "../layout/Admin";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { search, refund, refundAlert } from "../../actions/log";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import spinner from "../layout/spinner.gif";

const Log = ({ search, refund, refundAlert, logs, loading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [searchResults, setSearchResults] = useState([]);
  const [endDate, setEndDate] = useState(
    new Date().setMonth(startDate.getMonth() + 1)
  );
  const [formData, setFormData] = useState({
    type: "",
    startDate: new Date(),
    endDate: new Date().setMonth(startDate.getMonth() + 1),
  });

  const { type } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const startDateChanger = (date) => {
    setStartDate(date);
    setFormData({ ...formData, startDate: date.toISOString() });
  };
  const endDateChanger = (date) => {
    setEndDate(date);
    setFormData({ ...formData, endDate: date.toISOString() });
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setSearchResults(logs);
  }, [logs]);
  useEffect(() => {
    const results = logs.filter((item) =>
      item.invoice.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);
  var paymentLogs = "";
  if (logs.length !== 0) {
    paymentLogs = searchResults.map((item) => (
      <>
        <tr className="text-gray-700 dark:text-gray-400">
          <td className="px-4 py-3 text-sm">
            {moment.utc(item.date).format("YYYY-MM-DD HH:mm:ss")}
          </td>
          <td className="px-4 py-3 text-sm">{item.payment_type}</td>
          <td className="px-4 py-3 text-sm">{item.invoice}</td>
          <td className="px-4 py-3 text-sm">{item.amount}</td>
          <td className="px-4 py-3 text-sm">{item.payment_status}</td>
          <td className="px-4 py-3 text-sm">
            {item.refunded ? "refunded" : "N/A"}
          </td>
          <td className="px-4 py-3 text-sm">
            {item.refunded
              ? moment.utc(item.refunded_date).format("YYYY-MM-DD HH:mm:ss")
              : "N/A"}
          </td>
          <td className="px-4 py-3 text-sm">{item.printerId}</td>
          <td className="px-4 py-3 text-sm">{item.pages}</td>
          <td className="px-4 py-3 text-sm">{item.color_type}</td>
          <td className="px-4 py-3">
            <div className="flex items-center space-x-4 text-sm">
              <button
                className={`flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 rounded-lg focus:outline-none focus:shadow-outline-gray ${
                  item.refunded
                    ? "text-gray-500 dark:text-gray-600"
                    : "text-purple-600 dark:text-gray-400"
                }`}
                aria-label="Refund"
                onClick={() =>
                  item.payment_status === "unpiad"
                    ? refundAlert()
                    : refund(item.invoice)
                }
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                    clip-rule="evenodd"
                  />
                  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </>
    ));
  }

  return (
    <>
      <Admin>
        <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          Log
        </h2>
        <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="mt-4 text-sm">
            <span className="text-gray-700 dark:text-gray-400 font-bold">
              Payment type
            </span>
            <div className="mt-1">
              <label className="inline-flex items-center text-gray-600 dark:text-gray-400">
                <input
                  onChange={(e) => onChange(e)}
                  type="radio"
                  className="text-purple-600 focus:ring-purple-200 focus:ring-opacity-50 focus:ring"
                  value="socialpay"
                  name="type"
                ></input>
                <span className="ml-2">SocialPay</span>
              </label>
              <label className="inline-flex items-center ml-4 text-gray-600 dark:text-gray-400">
                <input
                  type="radio"
                  onChange={(e) => onChange(e)}
                  className="text-purple-600 focus:ring-purple-200 focus:ring-opacity-50 focus:ring"
                  value="qpay"
                  name="type"
                ></input>
                <span className="ml-2">QPay</span>
              </label>
              <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
                <input
                  type="radio"
                  onChange={(e) => onChange(e)}
                  className="text-purple-600 focus:ring-purple-200 focus:ring-opacity-50 focus:ring"
                  value="POS"
                  name="type"
                ></input>
                <span className="ml-2">POS</span>
              </label>

              <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400 mt-3">
                <span className="mr-2 mb-2 text-gray-600 dark:text-gray-400">
                  From:
                </span>

                <input
                  type="date"
                  name="startDate"
                  onChange={(e) => onChange(e)}
                ></input>
              </label>
              <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400 mt-3">
                <span className="mr-2 mb-2 text-gray-600 dark:text-gray-400 ">
                  To:
                </span>
                <input
                  type="date"
                  name="endDate"
                  onChange={(e) => onChange(e)}
                ></input>
              </label>
              <div className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400 mt-5">
                <button
                  className="px-4 py-2 block mt-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-200"
                  onClick={() => {
                    search(formData);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          <br></br>
          <label className="inline-flex mb-1 items-center ml-6 text-gray-600 dark:text-gray-400">
            <span className="w-full text-gray-600 dark:text-gray-400">
              Filter invoice:
            </span>
            <input
              type="text"
              value={searchTerm}
              onChange={handleChange}
              placeholder="invoice id"
              className="block w-full mt-1 ml-2 text-sm dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded-lg dark:bg-gray-700 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
            />
          </label>
        </div>

        <div className="w-full overflow-hidden rounded-lg shadow-xs">
          <div className="w-full overflow-x-auto">
            <table className="w-full whitespace-no-wrap">
              <thead>
                <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                  <th className="px-4 py-3">Date</th>
                  <th className="px-4 py-3">Payment type</th>
                  <th className="px-4 py-3">Invoice</th>
                  <th className="px-4 py-3">Amount</th>
                  <th className="px-4 py-3">Status</th>
                  <th className="px-4 py-3">Refunded</th>
                  <th className="px-4 py-3">Refunded Date</th>
                  <th className="px-4 py-3">Printer ID</th>
                  <th className="px-4 py-3">Pages</th>
                  <th className="px-4 py-3">Color type</th>
                  <th className="px-4 py-3">Refund</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {!loading ? (
                  paymentLogs
                ) : (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Loading...</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Admin>
    </>
  );
};

Log.propTypes = {
  getPrinters: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  logs: state.log.logs,
  loading: state.log.loading,
});

export default connect(mapStateToProps, { search, refund, refundAlert })(Log);
