import axios from "axios";
import {
  GET_PRINTERS,
  GET_ERROR,
  DISABLE_PRINTER,
  EDIT_PRINTER,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toastr } from "react-redux-toastr";
import { logout } from "./auth";

//  Get printer
export const getPrinters = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/printer");
    dispatch({
      type: GET_PRINTERS,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

// Disable printer
export const disablePrinter = (printerId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ printerId });

  try {
    const res = await axios.put("/api/printer/disable", body, config);
    dispatch({
      type: DISABLE_PRINTER,
      payload: res.data,
    });
    toastr.success("Printer has been disabled");
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

// Edit printer
export const editPrinter =
  (printerId, location, status, paper, paperDate, phoneNumber) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var error = "";
    if (status === false) {
      status = "";
    } else {
      status = "running";
      error = "none";
    }

    const body = JSON.stringify({
      printerId,
      location,
      status,
      paper,
      paperDate,
      phoneNumber,
      error,
    });

    try {
      const res = await axios.put("/api/printer", body, config);
      dispatch({
        type: EDIT_PRINTER,
        payload: res.data,
      });
      toastr.success("Printer has been edited");
    } catch (err) {
      if (
        err.response.status === 401 &&
        err.response.data.msg === "Token is not valid"
      ) {
        window.localStorage.clear("x-auth-token");
        toastr.error("Session expired");
        dispatch(logout());
      }
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => toastr.error(error.msg));
      }
    }
  };
