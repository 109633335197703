import axios from "axios";
import { CHANGE_PRICE, GET_ERROR, GET_PRODUCT } from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toastr } from "react-redux-toastr";
import { logout } from "./auth";

//  Get product
export const getProduct = () => async (dispatch) => {
	try {
		const res = await axios.get("/api/product");
		dispatch({
			type: GET_PRODUCT,
			payload: res.data,
		});
	} catch (err) {
		if (
			err.response.status === 401 &&
			err.response.data.msg === "Token is not valid"
		) {
			window.localStorage.clear("x-auth-token");
			toastr.error("Session expired");
			dispatch(logout());
		}
		dispatch({
			type: GET_ERROR,
		});
	}
};

// Change price
export const changePrice = (productId, price) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const body = JSON.stringify({ productId, price });

	try {
		const res = await axios.put("/api/product", body, config);
		dispatch({
			type: CHANGE_PRICE,
			payload: res.data,
		});
		toastr.success("Price has been changed");
	} catch (err) {
		if (
			err.response.status === 401 &&
			err.response.data.msg === "Token is not valid"
		) {
			window.localStorage.clear("x-auth-token");
			toastr.error("Session expired");
			dispatch(logout());
		}
		const errors = err.response.data.errors;
		if (errors) {
			errors.forEach((error) => toastr.error(error.msg));
		}
	}
};
