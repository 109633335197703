import axios from "axios";
import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	USER_LOADED,
	CLEAR_PRINTER,
	CLEAR_PRODUCT,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toastr } from "react-redux-toastr";

//  Load User
export const loadUser = () => async (dispatch) => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	try {
		const res = await axios.get("/api/auth/admin");
		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: LOGIN_FAIL,
		});
	}
};

// Login USER
export const login = (email, password) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	const body = JSON.stringify({ email, password });
	try {
		const res = await axios.post("/api/auth/admin", body, config);
		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});
		dispatch(loadUser());
	} catch (err) {
		const errors = err.response.data.errors;
		if (errors) {
			errors.forEach((error) => toastr.error(error.msg));
		}
		dispatch({
			type: LOGIN_FAIL,
		});
	}
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
	dispatch({
		type: LOGOUT,
	});
	dispatch({
		type: CLEAR_PRODUCT,
	});
	dispatch({
		type: CLEAR_PRINTER,
	});
};
