import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../auth/Login";
import Dashboard from "../dashboard/Dashboard";
import TenantDashboard from "../tenant/TenantDashboard";
import PrivateRoute from "../routing/PrivateRoute";
import Printer from "../printer/Printer";
import Settings from "../settings/Settings";
import Log from "../log/Log";

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/tenant" component={TenantDashboard} />
        <PrivateRoute exact path="/printer" component={Printer} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/log" component={Log} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
