import React, { useRef, useEffect, useState } from "react";
import Admin from "../layout/Admin";
import { getPrinters } from "../../actions/printer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import PrinterItem from "./PrinterItem";
import EditPrinter from "./EditPrinter";

const Printer = ({ getPrinters, printers, loading }) => {
  useEffect(() => {
    getPrinters();
  }, [getPrinters]);
  const [printerItem, editPrinter] = useState({});
  const [showModal, setShowModal] = useState(false);
  const toggle = (printer) => {
    setShowModal(!showModal);
    editPrinter(printer);
  };

  var printerData = "";
  if (printers.length !== 0) {
    printerData = printers.map((printer) => (
      <PrinterItem printer={printer} key={printer._id} editPrinter={toggle} />
    ));
  }

  return (
    <>
      {!loading ? (
        <Admin>
          <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
            Printer
          </h2>

          {printers.length !== 0 && (
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                      <th className="px-4 py-3">Printer name</th>
                      <th className="px-4 py-3">Paper</th>
                      <th className="px-4 py-3">Status</th>
                      <th className="px-4 py-3">Total printed</th>
                      <th className="px-4 py-3">Paper date</th>
                      <th className="px-4 py-3">Phone number</th>
                      <th className="px-4 py-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    {printerData}
                  </tbody>
                </table>
              </div>
              {showModal ? (
                <EditPrinter
                  printer={printerItem}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </Admin>
      ) : (
        <Spinner />
      )}
    </>
  );
};

Printer.propTypes = {
  getPrinters: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  printers: state.printer.printers,
  loading: state.printer.loading,
});

export default connect(mapStateToProps, {
  getPrinters,
})(Printer);
