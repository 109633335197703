export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_ERROR = "GET_ERROR";
export const CHANGE_PRICE = "CHANGE_PRICE";
export const GET_PRINTERS = "GET_PRINTERS";
export const DISABLE_PRINTER = "DISABLE_PRINTER";
export const EDIT_PRINTER = "EDIT_PRINTER";
export const CLEAR_PRINTER = "CLEAR_PRINTER";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const GET_COUNTS = "GET_COUNTS";
export const DARK_MODE = "DARK_MODE";
export const GET_LOG = "GET_LOG";
export const GET_LOG_ERROR = "GET_LOG_ERROR";
export const CHANGE_LOADING = "CHANGE_LOADING";
export const PRINTER_INCOME = "PRINTER_INCOME";
export const REFUND = "REFUND";
export const REFUND_ERROR = "REFUND_ERROR";
