import { useState, useRef, useEffect } from "react";
import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { ThemeProvider } from "./themeContext";

const Admin = ({ children }) => {
	const [collapseShow, setCollapseShow] = useState(true);
	const [profileShow, setProfileShow] = useState(true);
	const [notificationShow, setNotificationShow] = useState(true);

	return (
		<>
			<ThemeProvider>
				<div className="flex h-screen bg-gray-50 dark:bg-gray-900">
					<Sidebar
						collapseShow={collapseShow}
						setCollapseShow={setCollapseShow}
					/>
					<div className="flex flex-col flex-1 w-full">
						<Navbar
							collapseShow={collapseShow}
							setCollapseShow={setCollapseShow}
							profileShow={profileShow}
							setProfileShow={setProfileShow}
							notificationShow={notificationShow}
							setNotificationShow={setNotificationShow}
						/>
						<main className="h-full overflow-y-auto">
							<div className="container px-6 mx-auto grid">{children}</div>
						</main>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
};

export default Admin;
