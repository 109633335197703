import axios from "axios";
import { GET_COUNTS, GET_ERROR, PRINTER_INCOME } from "./types";
import { toastr } from "react-redux-toastr";
import { logout } from "./auth";

//  Get user count
export const getCounts = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/monitoring/counts");
    dispatch({
      type: GET_COUNTS,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

//  Get printer income
export const getIncome = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/monitoring/income", body, config);
    dispatch({
      type: PRINTER_INCOME,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

//  Get printer income for Tenant
export const getTenantIncome = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/monitoring/income-tenant", body, config);
    dispatch({
      type: PRINTER_INCOME,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};
