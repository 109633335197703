import React from "react";
import Chart from "react-apexcharts";

const PrinterIncome = ({ printerData }) => {
  if (!printerData || printerData.length === 0) {
    return <div>No data available</div>;
  }
  const categories = printerData.map(
    (item) => `${item.printerDetails.name} - ${item.printerDetails.location}`
  );

  const seriesData = printerData.map((item) => {
    const data = {};
    item.totalAmount.forEach((total) => {
      // Keep data as raw numbers
      data[total.color_type.slice(1).toUpperCase()] = total.totalAmount;
    });
    return data;
  });

  const series = Object.keys(seriesData[0]).map((colorType) => ({
    name: colorType,
    data: seriesData.map((data) => data[colorType] || 0), // Ensure data is a number or zero
  }));

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat("en-US").format(value);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat("en-US").format(value);
        },
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: true, // Enable data labels
      formatter: function (val) {
        return new Intl.NumberFormat("en-US").format(val); // Format values with thousands separator
      },
      style: {
        colors: ["#fff"], // Optional: change label colors if needed
      },
    },
    colors: ["#1E2F3C", "#7C3AED"],
  };

  return (
    <div className="w-full">
      <Chart options={options} series={series} type="bar" height="400" />
    </div>
  );
};

export default PrinterIncome;
