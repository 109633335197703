import React, { useEffect, Fragment, useState } from "react";
import Admin from "../layout/Admin";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTenantIncome } from "../../actions/dashboard";
import Spinner from "../layout/Spinner";
import PrinterIncome from "../dashboard/PrinterIncome";
import PrinterIncomeTable from "../dashboard/PrinterIncomeTable";
import DatePicker from "react-datepicker";
import { subDays, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const TenantDashboard = ({ getTenantIncome, dashboard, loading }) => {
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()
  );
  const [formData, setFormData] = useState({
    startDate,
    endDate,
  });
  useEffect(() => {
    getTenantIncome({ startDate, endDate });
  }, [getTenantIncome]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const startDateChanger = (date) => {
    setStartDate(date);
    setFormData({ ...formData, startDate: date.toISOString() });
  };
  const endDateChanger = (date) => {
    setEndDate(date);
    setFormData({ ...formData, endDate: date.toISOString() });
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {!loading ? (
        <Admin>
          <>
            <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Dashboard
            </h2>
            <div className="flex items-center justify-between p-4 mb-8 text-sm font-semibold text-purple-100 bg-purple-600 rounded-lg shadow-md focus:outline-none focus:shadow-outline-purple">
              <div className="flex items-center">
                <img src="/img/wise.png" className="w-9 h-9 mr-2" />
                <span>Welcome to CloudPrint Dashboard</span>
              </div>
            </div>

            <div className="mb-8">
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 mb-6">
                <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
                  <span className="mr-2 text-gray-600 dark:text-gray-400">
                    From:
                  </span>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(e) => onChange(e)}
                    className="text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md focus:border-purple-500 focus:outline-none"
                  ></input>
                </label>
                <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
                  <span className="mr-2 text-gray-600 dark:text-gray-400">
                    To:
                  </span>
                  <input
                    type="date"
                    name="endDate"
                    onChange={(e) => onChange(e)}
                    className="text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md focus:border-purple-500 focus:outline-none"
                  ></input>
                </label>
                <div className="ml-6">
                  <button
                    className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-200"
                    onClick={() => {
                      getTenantIncome(formData);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <PrinterIncome printerData={dashboard.printerIncome} />
            </div>
            <div className="mb-8">
              <PrinterIncomeTable printerData={dashboard.printerIncome} />
            </div>
          </>
        </Admin>
      ) : (
        <Spinner />
      )}
    </>
  );
};

TenantDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  loading: false,
});

export default connect(mapStateToProps, { getTenantIncome })(TenantDashboard);
