import { GET_ERROR, GET_COUNTS, PRINTER_INCOME } from "../actions/types";

const initialState = {
  userCount: null,
  printerCount: null,
  printerIncome: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COUNTS:
      return {
        ...state,
        loading: false,
        userCount: payload.userCount,
        printerCount: payload.printerCount,
      };
    case PRINTER_INCOME:
      return {
        ...state,
        loading: false,
        printerIncome: payload,
      };
    default:
      return state;
  }
}
