import {
  GET_LOG,
  GET_LOG_ERROR,
  CHANGE_LOADING,
  REFUND,
  REFUND_ERROR,
} from "../actions/types";

const initialState = {
  logs: [],
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LOG:
      return {
        ...state,
        loading: false,
        logs: payload,
      };
    case REFUND:
      return {
        ...state,
        logs: state.logs.map((item) =>
          item._id === payload._id ? payload : item
        ),
      };
    case GET_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
