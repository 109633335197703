import { combineReducers } from "redux";
import auth from "./auth";
import settings from "./settings";
import printer from "./printer";
import dashboard from "./dashboard";
import design from "./design";
import log from "./log";
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  auth,
  settings,
  printer,
  design,
  dashboard,
  log,
  toastr: toastrReducer,
});
