import { DARK_MODE } from "../actions/types";

const initialState = {
	darkMode: false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DARK_MODE:
			return {
				...state,
				darkMode: !state.darkMode,
			};

		default:
			return state;
	}
}
