import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import Routes from "./components/routing/Routes";
import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import ReduxToastr from "react-redux-toastr";
import store from "./store";
import "./App.css";

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

function App() {
	useEffect(() => {
		store.dispatch(loadUser());
	}, []);
	return (
		<Provider store={store}>
			<Router>
				<>
					<Route component={Routes}></Route>
					<ReduxToastr
						timeOut={2000}
						preventDuplicates
						newestOnTop={false}
						position="bottom-center"
						getState={(state) => state.toastr} // This is the default
						transitionIn="fadeIn"
						transitionOut="fadeOut"
					/>
				</>
			</Router>
		</Provider>
	);
}

export default App;
