import axios from "axios";
import {
  GET_LOG,
  GET_LOG_ERROR,
  CHANGE_LOADING,
  REFUND,
  REFUND_ERROR,
} from "./types";
import { toastr } from "react-redux-toastr";
import { logout } from "./auth";

//  Search log
export const search = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: CHANGE_LOADING });
    const body = JSON.stringify(formData);
    const res = await axios.post("/api/payment/log", body, config);
    dispatch({
      type: GET_LOG,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

//  Refund payment
export const refund = (invoice) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ invoice });
    const res = await axios.post("/api/payment/refund", body, config);
    dispatch({
      type: REFUND,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

//  Refund alert
export const refundAlert = () => async (dispatch) => {
  try {
    console.log("called");
    toastr.error("Can't refund unpaid payment");
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
  }
};
