import {
	GET_ERROR,
	GET_PRINTERS,
	DISABLE_PRINTER,
	EDIT_PRINTER,
	CLEAR_PRINTER,
} from "../actions/types";

const initialState = {
	printers: [],
	loading: true,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_PRINTERS:
			return {
				...state,
				loading: false,
				printers: payload,
			};
		case DISABLE_PRINTER:
			return {
				...state,
				printers: state.printers.map((item) =>
					item._id === payload._id ? payload : item
				),
			};
		case EDIT_PRINTER:
			return {
				...state,
				printers: state.printers.map((item) =>
					item._id === payload._id ? payload : item
				),
			};
		case GET_ERROR:
			return {
				...state,
				loading: false,
			};
		case CLEAR_PRINTER:
			return {
				...state,
				printers: [],
				loading: true,
			};
		default:
			return state;
	}
}
