import React from "react";

function PrintingPrices({ product }) {
	return (
		<>
			<div className="grid gap-6 mb-8 md:grid-cols-2">
				<div className="min-w-0 p-4 bg-gray-900 rounded-lg shadow-xs dark:bg-gray-800 text-center">
					<h4 className="mb-4 font-semibold text-white dark:text-gray-300 text-center text-3xl">
						{product[0].type}
					</h4>
					<p className="text-white dark:text-gray-400 text-2xl">
						{product[0].price}₮
					</p>
				</div>
				<div className="min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs text-center">
					<h4 className="mb-4 font-semibold text-center text-3xl dark:text-gray-300">
						{product[1].type}
					</h4>
					<p className="text-2xl dark:text-gray-400 text-white">
						{product[1].price}₮
					</p>
				</div>
			</div>
		</>
	);
}

export default PrintingPrices;
