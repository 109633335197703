import React, { useRef, useEffect, useState } from "react";
import Admin from "../layout/Admin";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { disablePrinter } from "../../actions/printer";

const PrinterItem = ({ printer, disablePrinter, editPrinter }) => {
  var colorForStatus = "";
  if (printer.status) {
    if (printer.status === "running") {
      colorForStatus =
        "text-green-700 bg-green-100 dark:bg-green-700 dark:text-green-100";
    } else if (printer.status === "error") {
      colorForStatus =
        "text-red-700 bg-red-100 dark:bg-red-700 dark:text-red-100";
    } else {
      colorForStatus =
        "text-gray-500 bg-gray-100 dark:bg-gray-700 dark:text-gray-400";
    }
  } else {
    colorForStatus =
      "text-gray-500 bg-gray-100 dark:bg-gray-700 dark:text-gray-400";
  }
  return (
    <>
      <tr className="text-gray-700 dark:text-gray-400">
        <td className="px-4 py-3">
          <div className="flex items-center text-sm">
            <div>
              <p className="font-semibold">{printer.printerName}</p>
              <p className="text-xs text-gray-600 dark:text-gray-400">
                {printer.location ? printer.location : "No location"}
              </p>
            </div>
          </div>
        </td>
        <td className="px-4 py-3 text-sm">
          {printer.paper ? printer.paper : 0}
        </td>
        <td className="px-4 py-3 text-xs">
          <span
            className={
              "px-2 py-1 font-semibold leading-tight rounded-full " +
              colorForStatus
            }
          >
            {printer.status ? printer.status : "No status"}
          </span>
        </td>
        <td className="px-4 py-3 text-sm">
          {printer.totalPrinted ? printer.totalPrinted : 0}
        </td>
        <td className="px-4 py-3 text-sm">
          {printer.paperDate ? printer.paperDate.split("T")[0] : ""}
        </td>
        <td className="px-4 py-3 text-sm">
          {printer.phoneNumber ? printer.phoneNumber : "NA"}
        </td>
        <td className="px-4 py-3">
          <div className="flex items-center space-x-4 text-sm">
            <button
              className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
              aria-label="Edit"
              onClick={() => editPrinter(printer)}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
              </svg>
            </button>
            <button
              className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
              aria-label="Delete"
              onClick={() => {
                disablePrinter(printer._id);
              }}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

PrinterItem.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { disablePrinter })(PrinterItem);
