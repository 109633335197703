import { NavLink } from "react-router-dom";
import { useRef, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Sidebar = ({ auth, collapseShow, setCollapseShow }) => {
  const ref = useRef();
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setCollapseShow(!collapseShow);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const fullAdminBar = (
    <>
      <aside className="z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0">
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <NavLink
            exact={true}
            to="/dashboard"
            activeClassName=""
            className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
          >
            CloudPrint
          </NavLink>

          <ul className="mt-6">
            <li className="relative px-6 py-3">
              <NavLink to="/dashboard" activeClassName="">
                {window.location.pathname.includes("/dashboard") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <div
                  className={
                    window.location.pathname.includes("/dashboard")
                      ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                      : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  }
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                  </svg>
                  <span className="ml-4">Dashboard</span>
                </div>
              </NavLink>
            </li>
          </ul>
          <ul>
            <li className="relative px-6 py-3">
              <NavLink to="/printer" activeClassName="">
                {window.location.pathname.includes("/printer") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <div
                  className={
                    window.location.pathname.includes("/printer")
                      ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                      : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  }
                >
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                    />
                  </svg>

                  <span className="ml-4">Printer</span>
                </div>
              </NavLink>
            </li>
            <li className="relative px-6 py-3">
              <NavLink to="/settings" activeClassName="">
                {window.location.pathname.includes("/settings") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <div
                  className={
                    window.location.pathname.includes("/settings")
                      ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                      : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  }
                >
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>

                  <span className="ml-4">Settings</span>
                </div>
              </NavLink>
            </li>
            <li className="relative px-6 py-3">
              {window.location.pathname.includes("/log") ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              <NavLink to="/log" activeClassName="">
                <div
                  className={
                    window.location.pathname.includes("/log")
                      ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                      : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  }
                >
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                    />
                  </svg>

                  <span className="ml-4">Log</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      <div
        className={
          collapseShow
            ? "fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center hidden"
            : "fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
        }
      ></div>
      {!collapseShow && (
        <aside
          className={
            collapseShow
              ? "fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden hidden"
              : "fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
          }
          ref={ref}
        >
          <div className="py-4 text-gray-500 dark:text-gray-400">
            <NavLink to="/dashboard" activeClassName="">
              <div className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200">
                CloudPrint
              </div>
            </NavLink>

            <ul className="mt-6">
              <li className="relative px-6 py-3">
                {window.location.pathname.includes("/dashboard") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <NavLink to="/dashboard" activeClassName="">
                  <div
                    className={
                      window.location.pathname.includes("/dashboard")
                        ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                        : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    }
                  >
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span className="ml-4">Dashboard</span>
                  </div>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="relative px-6 py-3">
                {window.location.pathname.includes("/printer") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <NavLink to="/printer" activeClassName="">
                  <div
                    className={
                      window.location.pathname.includes("/printer")
                        ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                        : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    }
                  >
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>
                    <span className="ml-4">Printer</span>
                  </div>
                </NavLink>
              </li>
              <li className="relative px-6 py-3">
                <NavLink to="/settings" activeClassName="">
                  {window.location.pathname.includes("/settings") ? (
                    <span
                      className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      window.location.pathname.includes("/settings")
                        ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                        : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    }
                  >
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                      />
                    </svg>

                    <span className="ml-4">Settings</span>
                  </div>
                </NavLink>
              </li>
              <li className="relative px-6 py-3">
                {window.location.pathname.includes("/log") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <NavLink to="/log" activeClassName="">
                  <div
                    className={
                      window.location.pathname.includes("/log")
                        ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                        : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    }
                  >
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                    </svg>
                    <span className="ml-4">Log</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
      )}
    </>
  );

  const partialAdminBar = (
    <>
      <aside className="z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0">
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <NavLink
            exact={true}
            to="/dashboard"
            activeClassName=""
            className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
          >
            CloudPrint
          </NavLink>

          <ul>
            <li className="relative px-6 py-3">
              <NavLink to="/printer" activeClassName="">
                {window.location.pathname.includes("/printer") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <div
                  className={
                    window.location.pathname.includes("/printer")
                      ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                      : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  }
                >
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                    />
                  </svg>

                  <span className="ml-4">Printer</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      <div
        className={
          collapseShow
            ? "fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center hidden"
            : "fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
        }
      ></div>
      {!collapseShow && (
        <aside
          className={
            collapseShow
              ? "fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden hidden"
              : "fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
          }
          ref={ref}
        >
          <div className="py-4 text-gray-500 dark:text-gray-400">
            <NavLink to="/dashboard" activeClassName="">
              <div className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200">
                CloudPrint
              </div>
            </NavLink>

            <ul className="mt-6">
              <li className="relative px-6 py-3">
                {window.location.pathname.includes("/dashboard") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <NavLink to="/dashboard" activeClassName="">
                  <div
                    className={
                      window.location.pathname.includes("/dashboard")
                        ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                        : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    }
                  >
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span className="ml-4">Dashboard</span>
                  </div>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="relative px-6 py-3">
                {window.location.pathname.includes("/printer") ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <NavLink to="/printer" activeClassName="">
                  <div
                    className={
                      window.location.pathname.includes("/printer")
                        ? "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100 text-gray-800"
                        : "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    }
                  >
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>
                    <span className="ml-4">Printer</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
      )}
    </>
  );

  var returnSideBar = "";
  if (auth.user) {
    if (auth.user.role === "full") returnSideBar = fullAdminBar;
    if (auth.user.role === "partial") returnSideBar = partialAdminBar;
  }

  return <>{!auth.userLoading && <>{returnSideBar}</>}</>;
};

Sidebar.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Sidebar);
