import React from "react";
import Chart from "react-apexcharts";

const PrinterIncomeTable = ({ printerData }) => {
  if (!printerData || printerData.length === 0) {
    return <div>No data available</div>;
  }
  var tableData = "";
  var grandTotal = 0; // This will hold the sum of all totalAmounts across all items

  if (printerData.length !== 0) {
    // Compute the grand total
    printerData.forEach((item) => {
      grandTotal += item.totalAmount?.reduce(
        (acc, curr) => acc + (curr?.totalAmount ?? 0),
        0
      );
    });

    // Map over printerData to create table rows
    tableData = printerData.map((item) => (
      <tr className="text-gray-700 dark:text-gray-400">
        <td className="px-4 py-3 text-sm">
          {item.printerDetails.name} - {item.printerDetails.location}
        </td>
        <td className="px-4 py-3 text-sm">
          {item.totalAmount?.[0]?.totalAmount?.toLocaleString() ?? "0"}
        </td>
        <td className="px-4 py-3 text-sm">
          {item.totalAmount?.[1]?.totalAmount?.toLocaleString() ?? "0"}
        </td>
        <td className="px-4 py-3 text-sm">
          {(
            (item.totalAmount?.[0]?.totalAmount ?? 0) +
            (item.totalAmount?.[1]?.totalAmount ?? 0)
          ).toLocaleString()}
        </td>
      </tr>
    ));

    // Add a final row for the grand total
    tableData.push(
      <tr className="text-gray-700 dark:text-gray-400">
        <td className="px-4 py-3 text-sm" colSpan="3">
          Grand Total
        </td>
        <td className="px-4 py-3 text-sm">{grandTotal.toLocaleString()}</td>
      </tr>
    );
  }

  return (
    <div className="w-full overflow-hidden rounded-lg shadow-xs">
      <div className="w-full overflow-x-auto">
        <table className="w-full whitespace-no-wrap">
          <thead>
            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
              <th className="px-4 py-3">Printer</th>
              <th className="px-4 py-3">Mono</th>
              <th className="px-4 py-3">Colored</th>
              <th className="px-4 py-3">Total</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {tableData}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrinterIncomeTable;
