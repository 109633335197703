import React, { Fragment } from "react";
import spinner from "./spinner.gif";
import Admin from "./Admin";

export default () => (
	<Fragment>
		<Admin>
			<div className="container px-6 mx-auto grid">
				<img
					src={spinner}
					className="my-6"
					style={{
						width: "50px",
						margin: "auto",
						display: "block",
					}}
					alt="Loading..."
				/>
			</div>
		</Admin>
	</Fragment>
);
