import React, { useRef, useEffect, useState } from "react";
import Admin from "../layout/Admin";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { editPrinter } from "../../actions/printer";

const EditPrinter = ({ printer, showModal, setShowModal, editPrinter }) => {
  const ref = useRef();
  const [statusToggle, setStatusToggle] = useState(false);
  const statusToggler = () => {
    setStatusToggle(!statusToggle);
  };

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowModal(!showModal);
      setStatusToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  var utc = new Date();
  utc.setHours(utc.getHours() + 8);
  const [formData, setFormData] = useState({
    printerId: printer._id,
    location: "",
    status: "",
    paper: "",
    paperDate: utc,
    phoneNumber: "",
  });
  const { printerId, location, status, paper, paperDate, phoneNumber } =
    formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = () => {};
  return (
    <>
      <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          ref={ref}
          className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
        >
          <header className="flex justify-end">
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </header>
          <div className="mt-4 mb-6">
            <div className="px-4 py-3 mb-8 bg-white dark:bg-gray-800">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Edit: {printer.printerName}
              </p>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 dark:text-gray-400">
                  Location
                </span>
                <input
                  onChange={(e) => onChange(e)}
                  type="text"
                  name="location"
                  className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded-lg dark:bg-gray-700 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
                />
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 dark:text-gray-400">
                  Phone number
                </span>
                <input
                  onChange={(e) => onChange(e)}
                  type="text"
                  name="phoneNumber"
                  className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded-lg dark:bg-gray-700 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
                />
              </label>
              <label className="block mt-4 text-sm">
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="inline-block text-gray-700 dark:text-gray-400">
                      Status:{" "}
                    </h2>
                    <p className="inline-block mx-2 text-gray-400 dark:text-gray-500">
                      Change status to running
                    </p>
                  </div>

                  <div
                    onClick={() => {
                      statusToggler();
                    }}
                    className={
                      !statusToggle
                        ? "w-12 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
                        : "w-12 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out bg-green-400"
                    }
                  >
                    <div
                      className={
                        !statusToggle
                          ? "bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out"
                          : "bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out translate-x-4"
                      }
                    ></div>
                  </div>
                </div>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 dark:text-gray-400">Paper</span>
                <input
                  onChange={(e) => onChange(e)}
                  type="text"
                  name="paper"
                  className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 border-purple-100 rounded-lg dark:bg-gray-700 focus:border-purple-200 focus:outline-none focus:ring-purple-200 focus:ring-2 dark:focus:shadow-outline-gray"
                />
              </label>
              {printer.status === "error" ? (
                <div className="block mt-4 bg-red-300 rounded-md p-1">
                  <h2 className="text-gray-700 dark:text-gray-400">
                    Error detail:
                  </h2>
                  <p className="text-sm text-gray-700 dark:text-gray-400 overflow-y-auto h-36">
                    {printer.error}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button
              onClick={() => setShowModal(false)}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-white text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            >
              Cancel
            </button>
            <button
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300"
              onClick={() => {
                editPrinter(
                  printerId,
                  location,
                  statusToggle,
                  paper,
                  paperDate,
                  phoneNumber
                );
              }}
            >
              Change
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

EditPrinter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { editPrinter })(EditPrinter);
